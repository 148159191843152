import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faWhatsapp,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import FootImg from "../../assets/Vector.jpeg";
import { Link } from "react-scroll";

const Footer = () => {
  useEffect(() => {
    const textElement = document.querySelector(".typing-text");
    const textContent = textElement.textContent.trim();
    const typedText = textContent.split("");
    let animatedText = "";

    const typeAnimation = () => {
      typedText.forEach((char, index) => {
        const delay = index * 150;

        setTimeout(() => {
          animatedText += char;
          textElement.textContent = animatedText;
        }, 2000 + delay);
      });

      setTimeout(() => {
        animatedText = "";
        textElement.textContent = "";
        typeAnimation();
      }, 2000 + typedText.length * 150);
    };
    typeAnimation();
  }, []);
  return (
    <footer>
      <div className="question">
        <h3 className="typing-text">Do you have any questions ?</h3>
        <p>
          Feel free to send us your questions or request a free consultation.
        </p>
        <Link
          to="contact"
          smooth={true}
          offset={-100}
          duration={1000}
          href="#Home"
        >
          <button className="btn">Send a Message</button>
        </Link>
      </div>
      <div className="row">
        <div className="col">
          <img src={FootImg} alt="" />
          <p>
            We help and manage a team of world-class developers to bring your
            vision of life.
          </p>
        </div>
        <div className="col">
          <h3>
            Contactus
            <div className="underline">
              <span></span>
            </div>
          </h3>
          <p>
            Plot No 20C, Duraisamy Naikker Street, Madhavaram, Chennai, Tamil
            Nadu - 600 060
          </p>
          <p className="email-id">support@invosys.co.in</p>
          <h4>+91 9962370333</h4>
        </div>
        <div className="col">
          <h3>
            Links
            <div className="underline">
              <span></span>
            </div>
          </h3>
          <ul>
            <li>
              <Link
                to="Home"
                smooth={true}
                offset={-155}
                duration={500}
                href="#Home"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="about"
                smooth={true}
                offset={-180}
                duration={500}
                href="#About"
              >
                About us
              </Link>
            </li>
            <li>
              <Link
                to="products-container"
                smooth={true}
                offset={-90}
                duration={500}
                href="#Products"
              >
                Our Products
              </Link>
            </li>
            <li>
              <Link
                to="cont-heading"
                smooth={true}
                offset={-120}
                duration={500}
                href="#Clients"
              >
                Our Clients
              </Link>
            </li>
          </ul>
        </div>

        <div className="social-icons">
          <a
            href="https://www.facebook.com/InvoSysSoftware"
            aria-label="Facebook"
          >
            <FontAwesomeIcon className="fab facebook " icon={faFacebook} />
          </a>
          <a href="https://wa.me/+919962370333" aria-label="WhatsApp">
            <FontAwesomeIcon className="fab whatsapp " icon={faWhatsapp} />
          </a>
          <a
            href="https://www.linkedin.com/company/invoking-systems-pvt-ltd/"
            aria-label="LinkedIn"
          >
            <FontAwesomeIcon className="fab linkdin" icon={faLinkedinIn} />
          </a>
          <a href="https://twitter.com/ELogiX2" aria-label="Twitter">
            <FontAwesomeIcon className="fab twitter" icon={faXTwitter} />
          </a>
        </div>
      </div>
      <hr />
      <p className="text">
        Copyright © 2019,{" "}
        <a style={{ color: "#0e33cb" }} href="https://invosys.co.in/">
          Invoking System Pvt. Ltd.
        </a>{" "}
        All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
